import React from "react";
import FirstSection from "./temp/FirstSection";

const Temp = () => {
    return (        
        <main className="temp">
            <img className="logo" src={require("../components/assets/img/header/menu/ic-logo.png")} alt="Gifthy" />
            <FirstSection />
        </main>
    )
}

export default Temp;